import axios from "axios";
const BASE_URL = "https://srinudola.com/controller/data.php";

const createPayment = async (payload) => {
  const response = await axios.post(BASE_URL, payload); // Adjust the endpoint as necessary
  return response.data;
};

async function getPaymentList(
  action,
  org_id,
  type,
  searchQuery,
  startDate,
  endDate
) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    action: action,
    org_id: org_id,
    filter: type || "",
    searchQuery: searchQuery || "",
    dateRangeStart: startDate || "",
    dateRangeEnd: endDate || "",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  const response = await fetch(`${BASE_URL}`, requestOptions);
  const data = await response.json();
  return data;
}

const getPaymentsDetailsById = async (action, org_id, paymentId) => {
  const response = await axios.post(BASE_URL, {
    action,
    org_id,
    paymentId,
  });
  return response.data;
};

const deletePayment = async (payload) => {
  const response = await axios.post(BASE_URL, payload); // Adjust the endpoint as necessary
  return response.data;
};

const UpdatePayment = async (payload) => {
  const response = await axios.post(BASE_URL, payload); // Adjust the endpoint as necessary
  return response.data;
};

const addUserDetails = async (payload) => {
  const response = await axios.post(BASE_URL, payload);
  return response.data;
};
const fetchUserDetails = async (org_id) => {
  const response = await axios.post(BASE_URL, org_id);
  return response.data;
};

const checkDueDates = async (payload) => {
  const response = await axios.post(BASE_URL, payload);
  return response.data;
};

const addBrandingDetails = async (payload) => {
  const response = await axios.post(BASE_URL, payload);
  return response.data;
};

export {
  BASE_URL,
  getPaymentList,
  createPayment,
  getPaymentsDetailsById,
  deletePayment,
  UpdatePayment,
  addUserDetails,
  fetchUserDetails,
  checkDueDates,
  addBrandingDetails,
};
