import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  UpdatePayment,
  addBrandingDetails,
  addUserDetails,
  createPayment,
  deletePayment,
  fetchUserDetails,
  getPaymentList,
} from "../api/api";
import toast, { Toaster } from "react-hot-toast";

const useCreatePayment = () => {
  const queryClient = useQueryClient();
  return useMutation(createPayment, {
    onSuccess: (data) => {
      if (data?.result.includes("Payment added successfully!")) {
        toast.success("Payment Added Successfully!");
        queryClient.invalidateQueries("fetch-payment-list");
      }
    },
    onError: (error: any) => {
      toast.error("An error occurred: " + error.message);
    },
  });
};

const useDeletePayment = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePayment, {
    onSuccess: (data) => {
      if (data?.result.includes("Payment deleted successfully!")) {
        toast.success("Payment Deleted Successfully!");
        queryClient.invalidateQueries("fetch-payment-list");
      }
    },
    onError: (error: any) => {
      toast.error("An error occurred: " + error.message);
    },
  });
};

const useUpdatePayment = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdatePayment, {
    onSuccess: (data) => {
      if (data?.result.includes("Payment updated successfully!")) {
        toast.success("Payment updated successfully!");
        queryClient.invalidateQueries("fetch-payment-list");
      }
    },
    onError: (error: any) => {
      toast.error("An error occurred: " + error.message);
    },
  });
};

function useGetPaymentList(
  action: string,
  org_id: string | undefined,
  type: string,
  searchQuery: string,
  startDate?: string | undefined,
  endDate?: string | undefined
) {
  return useQuery(
    [
      "fetch-payment-list",
      action,
      org_id,
      type,
      searchQuery,
      startDate,
      endDate,
    ],
    () => getPaymentList(action, org_id, type, searchQuery, startDate, endDate),
    {
      enabled: !!org_id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
}

const useAddUserDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(addUserDetails, {
    onSuccess: (data) => {
      if (data?.result.includes("User Details updated successfully!")) {
        toast.success("User details Added Successfully!");
        queryClient.invalidateQueries("fetch-user-details");
      }
    },
    onError: (error: any) => {
      toast.error("An error occurred: " + error.message);
    },
  });
};

const useAddUserBranding = () => {
  const queryClient = useQueryClient();
  return useMutation(addUserDetails, {
    onSuccess: (data) => {
      if (data?.result.includes("Branding updated successfully!")) {
        toast.success("Branding Details Added Successfully!");
        queryClient.invalidateQueries("fetch-user-details");
      }
    },
    onError: (error: any) => {
      toast.error("An error occurred: " + error.message);
    },
  });
};

const useUpdateUserBranding = () => {
  const queryClient = useQueryClient();
  return useMutation(addBrandingDetails, {
    onSuccess: (data) => {
      if (data?.result.includes("Branding updated successfully!")) {
        toast.success("Branding updated successfully!");
        queryClient.invalidateQueries("fetch-brand-details");
      }
    },
    onError: (error: any) => {
      toast.error("An error occurred: " + error.message);
    },
  });
};

const useGetBrandDetails = (org_id: any) => {
  return useQuery(
    ["fetch-brand-details", org_id],
    () => fetchUserDetails(org_id),
    {
      enabled: !!org_id,
      refetchOnWindowFocus: false,
    }
  );
};

const useGetUserDetails = (org_id: any) => {
  return useQuery(
    ["fetch-user-details", org_id],
    () => fetchUserDetails(org_id),
    {
      enabled: !!org_id,
      refetchOnWindowFocus: false,
    }
  );
};

const useCheckDueDates = (payload: any) => {
  return useQuery(["checkDueDates", payload], () => fetchUserDetails(payload), {
    enabled: !!payload,
    refetchOnWindowFocus: false,
  });
};
export {
  useGetPaymentList,
  useCreatePayment,
  useDeletePayment,
  useUpdatePayment,
  useAddUserDetails,
  useGetUserDetails,
  useCheckDueDates,
  useAddUserBranding,
  useUpdateUserBranding,
  useGetBrandDetails,
};
