import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import { BASE_URL, getPaymentsDetailsById } from "../api/api";
import { Button } from "../components/Button/button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useCreatePayment, useDeletePayment } from "../data/data";
import Loader from "../components/Loader/loader";

type createProps = {
  closeDialog: any;
  selectDeletedId: string;
};

const DeletePayment = ({ closeDialog, selectDeletedId }: createProps) => {
  const user_id = Cookies.get("id");
  const [isBtnLoad, setIsBtnLoad] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<any>({});

  function handleClose() {
    closeDialog(false);
  }

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const details = await getPaymentsDetailsById(
          "getPaymentsDetailsById",
          user_id,
          selectDeletedId
        );
        setSelectedPayment(details);
      } catch (err: any) {
        console.log(err.message);
      }
    };

    if (user_id && selectDeletedId) {
      fetchPaymentDetails();
    }
  }, [user_id, selectDeletedId]);

  const { mutate } = useDeletePayment();

  const handleDeletePayment = (e: any) => {
    e.preventDefault();
    setIsBtnLoad(true);
    const payload = {
      action: "deletePayment",
      paymentId: selectDeletedId,
      org_id: user_id,
    };

    mutate(payload, {
      onSuccess: () => {
        setIsBtnLoad(false);
        setTimeout(() => {
          closeDialog(false);
        }, 300);
      },
    });
  };

  return (
    <div
      style={{ backgroundColor: "#00000073" }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
    >
      <div className="relative p-4 w-4/12 max-h-full">
        <div className="relative bg-white rounded-md shadow p-4">
          <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10">
              Delete Payment
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.2}
              stroke="currentColor"
              className="size-5 cursor-pointer"
              onClick={handleClose}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          {selectedPayment?.length ? (
            <span className="text-sm font-medium pt-4 w-full flex flex-col">
              Are You sure you want to Delete this Payment ?
              <span className="capitalize font-medium pt-1 text-indigo-600">
                {selectedPayment[0]?.payment_name}
              </span>
            </span>
          ) : (
            <span className="text-sm font-medium pt-4 w-full flex">
              <Loader withWhiteBg={true} />
            </span>
          )}

          <footer className="py-2 flex justify-end mt-4">
            <Button
              className="items-center flex  justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-slate-700 "
              type="button"
              onClick={handleClose}
              children={"Cancel"}
            />
            <Button
              type={"submit"}
              isButtonLoaded={isBtnLoad}
              className={
                "items-center flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={handleDeletePayment}
              children={"Delete"}
              disabled={!selectedPayment[0]?.payment_name}
            />
          </footer>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export { DeletePayment };
