import Cookies from "js-cookie";
import React, { useState } from "react";
import { PieChartData } from "../modules/Dashboard/pieChart";
import { BarChartData } from "../modules/Dashboard/barChart";
import { TotalAnalytics } from "../modules/Dashboard/totalAnalytics";
import { BarChartTimeFrame } from "../modules/Dashboard/BarChartTimeFrame";
import { BarChartByYear } from "../modules/Dashboard/BarChartByYear";

const DashBoardCharts = () => {
  const user_id = Cookies.get("id");

  return (
    <div>
      <div className="flex flex-row">
        <div className="animate-fadeIn  w-2/4  rounded-md py-5 h-96 mr-4 pr-4">
          <TotalAnalytics />
          {/* <h2 className="font-semibold text-base mb-4 px-4">Pie Chart</h2>
        <PieChartData /> */}
        </div>
        <div className="animate-fadeIn bg-white w-2/4 shadow-sm rounded-md py-5 h-96 px-2">
          <BarChartData />
        </div>
      </div>
      <div className="flex flex-row mt-4">
        <div className="animate-fadeIn bg-white w-2/4 shadow-sm rounded-md py-5 h-96 mr-4 px-2">
          <BarChartTimeFrame />
        </div>
        <div className="animate-fadeIn bg-white w-2/4 shadow-sm rounded-md py-5 h-96 px-2">
          <BarChartByYear />
        </div>
      </div>
    </div>
  );
};

export { DashBoardCharts };
