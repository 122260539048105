import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/Button/button";
import { AddPayment } from "../modules/addPayment";
import logo from "../assets/logo.png";
import {
  useCheckDueDates,
  useGetBrandDetails,
  useGetPaymentList,
  useGetUserDetails,
} from "../data/data";
import Loader from "../components/Loader/loader";
import { useUser } from "../context/UserContext";

//import { useCookies } from "react-cookie";

const TopNav = (onToggle: any) => {
  const org_id = Cookies.get("id");
  const org_email = Cookies.get("email");
  const [toggleArrow, setToggleArrow] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState<any>();
  const currentDate = new Date();
  const [bgColor, setBgColor] = useState("#ffffff"); // Default color

  const navigate = useNavigate();
  function onClick(e: any) {
    Cookies.remove("id", { path: "/" });
    navigate("/login");
  }

  function handleDialog() {
    setOpenDialog((current) => !current);
  }

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const notificationRef = useRef<any>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setOpenNotificationDialog(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    setBgColor(getRandomColor());
  }, []);

  function handleClose() {
    setOpenDialog(false);
  }

  const { data, isLoading, error, isFetching } = useGetUserDetails({
    action: "getUserDetails",
    org_id,
  });
  const { data: brandData, isLoading: brandLoading } = useGetBrandDetails({
    action: "getBrandingInfo",
    org_id,
  });
  console.log("brandData", brandData);
  const userDetails = useMemo(() => data, [data, org_id]);

  const { data: rowData, refetch } = useGetPaymentList(
    "getPaymentsList",
    org_id,
    type,
    searchQuery
  );
  useEffect(() => {
    const filteredData = rowData?.filter(
      (row: any) =>
        new Date(row.due_date) < currentDate && row.payment_status !== "paid"
    );
    setNotificationCount(filteredData?.length);
    setNotificationData(filteredData);
  }, [rowData]);

  function handleNotificationDetails() {
    setOpenNotificationDialog(!openNotificationDialog);
  }

  return (
    <aside
      className="bg-white shadow-sm flex justify-between fixed  px-4 top-0 left-0  w-screen h-11 transition-transform -translate-x-full sm:translate-x-0 z-30"
      aria-label="Sidebar"
    >
      <div className="flex items-center">
        {brandLoading ? (
          <Loader withWhiteBg={true} size="small" />
        ) : brandData?.data?.[0]?.BrandingLogo ? (
          <img
            src={`data:image/png;base64,${brandData?.data?.[0]?.BrandingLogo}`}
            alt="Attachment"
            className="logo"
          />
        ) : (
          <img src={logo} className="logo" />
        )}
      </div>

      <div className="flex items-center">
        <Button
          type={"submit"}
          className={
            "mx-4 text-xs items-center flex justify-center rounded-md bg-indigo-600 px-3  h-7   text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleDialog}
          children={"Add Payment"}
        />
        <div className="relative" ref={notificationRef}>
          <div className="cursor-pointer" onClick={handleNotificationDetails}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
              />
            </svg>
            {notificationCount > 0 && (
              <small
                className="text-xs absolute bg-red-500 text-white rounded-full w-3 h-3 right-1 flex items-center	justify-evenly"
                style={{ top: -2, right: 6, fontSize: 10 }}
              >
                {notificationCount}
              </small>
            )}
          </div>
          {openNotificationDialog && (
            <div
              className="absolute right-0 top-9 bg-white py-4 px-4 w-full rounded-md text-sm shadow-md"
              style={{ width: 400 }}
            >
              <h2 className="font-medium pb-2 border-b">Notifications</h2>
              <div>
                {notificationData?.map((e: any) => {
                  return (
                    <div className="mt-2 flex justify-between pb-4">
                      <div>
                        <div className="text-sm font-medium capitalize">
                          {e.payment_name}
                        </div>
                        <div className="text-xs font-normal capitalize">
                          {e.notes}
                        </div>
                      </div>

                      <div>Read</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="dropdown dropdown-end" ref={dropdownRef}>
          <div
            tabIndex={0}
            role="button"
            className="m-1"
            onClick={toggleDropdown}
          >
            <span
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white shadow bg-slate-200 my-2 flex items-center justify-center	 text-white"
              style={{ backgroundColor: bgColor }}
            >
              {isLoading || isFetching ? (
                <Loader withWhiteBg={false} />
              ) : userDetails?.profile_pic ? (
                <img
                  src={`data:image/png;base64,${userDetails?.profile_pic}`}
                  alt="Attachment"
                  className=" w-8 h-8 rounded-full"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>
              )}
            </span>
          </div>
          {isOpen && (
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-md mr-4 w-40 absolute right-2 bg-white"
            >
              <li className="text-sm font-semibold  pl-2 mb-2 flex flex-col">
                <span className="capitalize">
                  {userDetails?.NAME.toUpperCase()}
                </span>
                <small className="text-slate-400">{org_email}</small>
              </li>
              <Link
                to="/portal/profile"
                className="hover:bg-gray-200 hover:text-indigo-600 text-xs py-2 px-2 w-full flex font-medium"
              >
                Profile
              </Link>
              <li
                className="hover:bg-gray-200 text-xs py-2 px-2 hover:text-indigo-600 font-medium"
                role="button"
                onClick={onClick}
              >
                <a>Sign Out</a>
              </li>
            </ul>
          )}
        </div>
      </div>
      {openDialog ? <AddPayment closeDialog={handleClose} /> : null}
    </aside>
  );
};

export { TopNav };
