import React, { useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Cookies from "js-cookie";
import { useGetPaymentList } from "../data/data";
import Loader from "../components/Loader/loader";
import { DeletePayment } from "../modules/deletePayment";
import { AddPayment } from "../modules/addPayment";
import { debounce } from "lodash";
import { DashBoardCharts } from "./Charts";

const DashBoard = () => {
  return (
    <div>
      <h1 className="font-bold text-lg mb-4">DashBoard</h1>
      <div className="w-full mb-8">
        <DashBoardCharts />
      </div>
    </div>
  );
};

export { DashBoard };
