import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Button } from "../components/Button/button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import logo from "../assets/logo.png";

const SignUp = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const [validationMsg, setValidationMsg] = useState<any>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const isFormValid =
      // name &&
      // mobile.length === 10 &&
      email && password && confirmPassword && password === confirmPassword;
    setIsButtonDisabled(!isFormValid);
  }, [name, mobile, email, password, confirmPassword]);

  const handleSignUp = (e: any) => {
    e.preventDefault();
    setIsbtnLoad(true);

    if (password !== confirmPassword) {
      setValidationMsg("Passwords do not match");
      setIsbtnLoad(false);
      return;
    }

    const signUpAsync = async () => {
      try {
        const response = await axios.post(
          `https://srinudola.com/controller/data.php`,
          {
            action: "signUp",
            email,
            password,
            confirmPassword,
          }
        );
        setValidationMsg(response?.data?.result);
        if (response?.data?.result === "Signup successful!") {
          toast.success("SignUp successful!");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(response?.data?.result);
        }
      } catch (error) {
        console.log("error: " + error);
        toast.error("error: " + error);
      } finally {
        setIsbtnLoad(false);
      }
    };

    signUpAsync();
  };

  return (
    <div className="flex h-screen justify-center flex-col lg:px-8 items-center bg-slate-100">
      <div className="w-96 px-4 bg-white rounded-md py-4">
        <div className="">
          <img className="my-1 mx-auto h-8" src={logo} alt="Your Company" />
          <h2 className="text-center text-xxl font-bold leading-9 tracking-tight text-gray-900">
            Sign Up For Free
          </h2>
          <p className="text-center px-6 w-full text-xs text-slate-400 mt-2">
            Enter your details below to create your account and get started.{" "}
          </p>
        </div>
        <div className="my-6 px-4">
          <form className="space-y-4" onSubmit={handleSignUp}>
            <div className="text-left">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 w-full"
              >
                Email address
              </label>
              <div className="mt-2 w-full">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {confirmPassword && password !== confirmPassword && (
                <small className="text-rose-700">Passwords do not match</small>
              )}
            </div>
            <div>
              <Button
                type={"submit"}
                isButtonLoaded={isbtnLoad}
                disabled={isButtonDisabled}
                className={`items-center flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                  isButtonDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                }`}
                onClick={handleSignUp}
                children={"Sign Up"}
              />
            </div>
          </form>
          <p className="text-center px-6 w-full text-xs text-slate-400 mt-4">
            Already have an account?
            <Link to="/login" className="px-1 text-indigo-500">
              Login
            </Link>
          </p>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export { SignUp };
