import Cookies from "js-cookie";
import React, { useMemo, useState } from "react";
import { useGetPaymentList } from "../../data/data";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../../components/Loader/loader";
import moment from "moment";

const BarChartTimeFrame = () => {
  const user_id = Cookies.get("id");
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [timeFrame, setTimeFrame] = useState("week");

  const { data, isLoading, isFetching, refetch } = useGetPaymentList(
    "getPaymentsList",
    user_id,
    type,
    searchQuery
  );

  const filteredData = useMemo(() => {
    const now = moment();
    let startDate: any;

    switch (timeFrame) {
      case "week":
        startDate = now.clone().subtract(1, "weeks");
        break;
      case "month":
        startDate = now.clone().subtract(1, "months");
        break;
      case "6months":
        startDate = now.clone().subtract(6, "months");
        break;
      case "year":
        startDate = now.clone().subtract(1, "years");
        break;
      default:
        startDate = now.clone().subtract(1, "weeks");
    }

    return data?.filter(
      (item: any) =>
        item.payment_status === "paid" &&
        moment(item.due_date, "YYYY-MM-DD").isBetween(startDate, now)
    );
  }, [data, timeFrame]);

  const processedData = useMemo(() => {
    const result: any = [];
    filteredData?.forEach((item: any) => {
      const date = item.due_date;
      const existingItem = result.find((d: any) => d.due_date === date);
      if (existingItem) {
        if (item.payment_type === "credited") {
          existingItem.credited += parseFloat(item.amount);
        } else if (item.payment_type === "debited") {
          existingItem.debited += parseFloat(item.amount);
        }
        existingItem.payment_names.push(item.payment_name);
      } else {
        result.push({
          due_date: date,
          credited:
            item.payment_type === "credited" ? parseFloat(item.amount) : 0,
          debited:
            item.payment_type === "debited" ? parseFloat(item.amount) : 0,
          payment_names: [item.payment_name],
        });
      }
    });

    // Sort the result by due_date
    return result.sort(
      (a: any, b: any) =>
        new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
    );
  }, [filteredData]);

  if (isLoading || isFetching) {
    return (
      <div className="flex justify-evenly items-center h-full">
        <Loader size="medium" withWhiteBg={true} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const handleTimeFrameChange = (timeFrame: string) => {
    setTimeFrame(timeFrame);
  };

  return (
    <div>
      <div className="flex justify-between px-4 items-baseline">
        <h2 className="font-semibold text-base mb-4">
          Payment Overview by Time Frame
        </h2>
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded ${
              timeFrame === "week"
                ? "bg-indigo-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handleTimeFrameChange("week")}
          >
            1W
          </button>
          <button
            className={`px-4 py-2 rounded ${
              timeFrame === "month"
                ? "bg-indigo-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handleTimeFrameChange("month")}
          >
            1M
          </button>
          <button
            className={`px-4 py-2 rounded ${
              timeFrame === "6months"
                ? "bg-indigo-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handleTimeFrameChange("6months")}
          >
            6M
          </button>
          <button
            className={`px-4 py-2 rounded ${
              timeFrame === "year"
                ? "bg-indigo-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handleTimeFrameChange("year")}
          >
            1Y
          </button>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={processedData}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="due_date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="credited" fill="#82ca9d" />
          <Bar dataKey="debited" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export { BarChartTimeFrame };
