import React, { useEffect, useState } from "react";
import { Button } from "../components/Button/button";
import Cookies from "js-cookie";
import {
  useAddUserBranding,
  useAddUserDetails,
  useGetBrandDetails,
  useGetUserDetails,
  useUpdateUserBranding,
} from "../data/data";
import toast, { Toaster } from "react-hot-toast";
import { FormEvent } from "react";
import { useUser } from "../context/UserContext";

const Settings = () => {
  const org_id = Cookies.get("id");

  const [isBtnLoad, setIsBtnLoad] = useState(false);
  const {
    data: brandData,
    isLoading: brandLoading,
    isFetching: brandFetching,
  } = useGetBrandDetails({
    action: "getBrandingInfo",
    org_id,
  });
  const [currencyName, setCurrencyName] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (brandData) {
      setCurrencyName(brandData?.data?.[0]?.currency ?? "");
      setPrimaryColor(brandData?.data?.[0]?.primaryColor ?? "");
      setFileBase64(brandData?.data?.[0]?.BrandingLogo ?? "");
    }
  }, [brandData]);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result
            .toString()
            .replace("data:", "")
            .replace(/^.+,/, "");
          setFileBase64(base64String);
          setError(null); // Clear any previous errors
        } else {
          setError("Failed to read file content.");
        }
      };
      reader.onerror = (error) => {
        setError("Failed to read file");
        console.error("Error reading file: ", error);
      };
      reader.readAsDataURL(file);
    } else {
      setError("No file selected");
    }
  };

  const { mutate } = useAddUserBranding();
  const { mutate: update } = useUpdateUserBranding();

  console.log(brandData?.data?.[0]?.id);

  const handleAddDetails = async (e: FormEvent) => {
    e.preventDefault();
    setIsBtnLoad(true);

    const payload = {
      currency: currencyName,
      primaryColor: primaryColor,
      fileBase64: fileBase64,
      org_id: org_id,
      action: "addBranding",
    };

    if (brandData?.data?.[0]?.id) {
      const id = brandData?.data?.[0]?.id;
      const data = {
        ...payload,
        id: Number(id),
        action: "updateBranding",
      };
      update(data, {
        onSuccess: () => {
          setIsBtnLoad(false);
          toast.success("Updated Successfully!");
        },
        onError: (error) => {
          setIsBtnLoad(false);
          toast.error(`Error Updating Data: ${error.message}`);
        },
      });
    } else {
      mutate(payload, {
        onSuccess: () => {
          setIsBtnLoad(false);
          toast.success("Added Successfully!");
        },
        onError: (error) => {
          setIsBtnLoad(false);
          toast.error(`Error Adding Data: ${error.message}`);
        },
      });
    }
  };
  console.log("primaryColor", primaryColor);
  return (
    <div>
      <h1 className="font-bold text-lg mb-4">Settings</h1>
      <div className="animate-fadeIn bg-white w-100 shadow-sm rounded-md p-4">
        <form className="pt-4 w-1/4">
          <div className="my-2">
            <label
              htmlFor="email"
              className="block text-xs text-gray-900  text-start leading-8 pe-2"
            >
              Select Currency Type
            </label>

            <div className=" w-full">
              <select
                value={currencyName}
                onChange={(e) => setCurrencyName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option>Select</option>
                <option value={"usd"}>USD</option>
                <option value={"inr"}>INR</option>
              </select>
            </div>
          </div>
          <div className="my-2">
            <label
              htmlFor="email"
              className="block text-xs text-gray-900  text-start leading-8 pe-2"
            >
              Primary Color
            </label>

            <div className=" w-full">
              <input
                type="color"
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
                className="bg-gray-50 border h-14 border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select color"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-gray-900  text-start leading-8 pe-2">
              Branding Logo
            </label>
            <div className="flex relative flex-col">
              <input
                type="file"
                onChange={handleFileChange}
                className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400
    file:bg-gray-50 file:border-0
    file:me-4
    file:py-3 file:px-4
    dark:file:bg-neutral-700 dark:file:text-neutral-400
                "
              />
              <small className="py-1 text-slate-400">
                Please upload an image in PNG or JPG format with a height of
                20px.{" "}
              </small>
              {fileBase64?.length ? (
                <img
                  src={`data:image/png;base64,${fileBase64}`}
                  alt="Attachment"
                  className="absolute m-2 h-5"
                  style={{ right: "-80px", top: "7px" }}
                />
              ) : null}
            </div>
          </div>

          {error && <p className="text-red-500">{error}</p>}
          <footer className="py-2 flex justify-end mt-4">
            <Button
              type={"submit"}
              isButtonLoaded={isBtnLoad}
              className={
                "items-center flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={handleAddDetails}
              children={"Save"}
            />
          </footer>
        </form>
      </div>
      <Toaster />
    </div>
  );
};
export { Settings };
