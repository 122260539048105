import React, { useEffect, useState } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import { BASE_URL } from "../api/api";
import { Button } from "../components/Button/button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useCreatePayment, useUpdatePayment } from "../data/data";
import { jsPDF } from "jspdf";
import companyLogo from "../assets/logo.png";

type createProps = {
  closeDialog: any;
  selectedData?: any;
};

type PaymentPayload = {
  action: string;
  paymentName: any;
  amount: any;
  payment_type: string;
  payment_mode: string;
  payment_status: string;
  dueDate: any;
  notes: any;
  fileBase64: string;
  org_id: string | undefined;
  paymentId?: any;
};

//const companyLogo = "../assets/logo.png";

const ShowPaymentDetails = ({ closeDialog, selectedData }: createProps) => {
  function handleClose() {
    closeDialog(false);
  }

  const [logoBase64, setLogoBase64] = useState("");

  useEffect(() => {
    const toBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    fetch(companyLogo)
      .then((res) => res.blob())
      .then((blob) => toBase64(blob))
      .then((base64: any) => setLogoBase64(base64));
  }, []);

  const entries = Object.entries(selectedData).filter(
    ([key]) => key !== "id" && key !== "org_id"
  );

  const getImageSrc = (base64: any) => {
    if (base64.startsWith("/9j/")) {
      return `data:image/jpeg;base64,${base64}`;
    } else if (base64.startsWith("iVBORw0KGgo")) {
      return `data:image/png;base64,${base64}`;
    } else {
      return undefined;
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const date = new Date().toLocaleDateString();
    let yOffset = 50; // Adjust for header space

    // Add company logo
    if (logoBase64) {
      try {
        doc.addImage(logoBase64, "PNG", 10, 13, 50, 8); // Adjust size and position as needed
      } catch (error) {
        console.error("Error adding image:", error);
      }
    }

    // Add downloaded date
    doc.text(`Downloaded Date: ${date}`, 142, 20); // Adjust position as needed

    const labelX = 10;
    const valueX = doc.internal.pageSize.width - 10;

    entries.forEach(([label, value], index) => {
      if (label !== "file_base64") {
        const stringValue = value ? String(value) : "N/A";
        doc.text(`${label.replace(/_/g, " ").toUpperCase()}:`, labelX, yOffset);
        doc.text(stringValue.toUpperCase(), valueX, yOffset, {
          align: "right",
        });
        yOffset += 10;
      }
    });

    if (selectedData?.file_base64) {
      const imgData = getImageSrc(selectedData?.file_base64);
      if (imgData) {
        try {
          doc.addImage(imgData, "JPEG", 10, yOffset, 50, 50); // Adjust size and position as needed
          yOffset += 60; // Adjust for image space
        } catch (error) {
          console.error("Error adding image:", error);
        }
      }
    }

    // Add horizontal lines at the bottom
    const pageHeight = doc.internal.pageSize.height;
    doc.setLineWidth(0.5);
    doc.line(10, pageHeight - 30, 200, pageHeight - 30);
    doc.line(10, pageHeight - 25, 200, pageHeight - 25);

    doc.save("payment_details.pdf");
  };
  return (
    <div
      style={{ backgroundColor: "#00000073" }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
    >
      <div className="relative p-4 w-5/12 max-h-full">
        <div className="relative bg-white rounded-md shadow p-4">
          <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10 capitalize">
              Payment Details
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.2}
              stroke="currentColor"
              className="size-5 cursor-pointer"
              onClick={handleClose}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="p-6 bg-white   w-full mx-auto">
            <div className="grid grid-cols-1 gap-4 text-sm">
              {entries.map(
                ([label, value], index) =>
                  label !== "file_base64" && (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <span className="font-medium text-gray-600 capitalize">
                        {label.replace(/_/g, " ")}
                      </span>
                      <span className="font-medium capitalize">
                        {String(value) || "N/A"}
                      </span>
                    </div>
                  )
              )}
              {selectedData?.file_base64 && (
                <div className="flex justify-between mt-4 items-start">
                  <span className="font-medium text-gray-600 capitalize">
                    Attachment
                  </span>
                  <img
                    src={getImageSrc(selectedData?.file_base64)}
                    alt="file"
                    className="h-20 w-20"
                  />
                </div>
              )}
            </div>
          </div>
          <footer className="py-2 flex justify-end mt-4">
            <button
              onClick={handleClose}
              type="button"
              className="items-center flex  justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-slate-700 "
            >
              Cancel
            </button>
            <button
              onClick={generatePDF}
              className=" text-xs items-center flex justify-center rounded-md bg-indigo-600 px-3   text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Download as PDF
            </button>
          </footer>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export { ShowPaymentDetails };
