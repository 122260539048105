import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import bg from "../assets/banner1.png";
import bg1 from "../assets/bg1.jpg";
import { Button } from "../components/Button/button";

const Home = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      label: "Enter Expenses",
      helpText: "Record your debit and credit transactions",
      content:
        "Easily log both your debit and credit transactions to keep track of all your expenses.",
    },
    {
      id: 1,
      label: "View Dashboard",
      helpText: "Get a comprehensive view of your expenses",
      content:
        "Get a clear overview of where your money is going with an intuitive dashboard.",
    },
    {
      id: 2,
      label: "Generate Reports",
      helpText: "Download reports based on your selected criteria",
      content:
        "Create custom reports to dive deeper into your spending patterns and trends.",
    },
  ];
  const navigate = useNavigate();
  function handleLogin() {
    navigate("/login");
  }
  function handleSignUp() {
    navigate("/signup");
  }
  return (
    <div className=" overflow-hidden bg-gray-100">
      <header className="relative">
        <div className="">
          <div className="px-4 sm:px-6 md:px-8 relative shadow-sm h-14 flex items-center justify-between text-slate-700 font-semibold text-sm leading-6 dark:text-slate-200 bg-white">
            <div className="flex">
              <img src={logo} className="h-4"></img>
              <span className="px-1 text-sm">Beta</span>
            </div>
            <div className="flex items-center">
              <div className="hidden md:flex items-center">
                <nav>
                  <ul className="flex items-center gap-x-8">
                    <li>
                      <a
                        className="hover:text-indigo-500 dark:hover:text-indigo-400"
                        href="/docs/installation"
                      >
                        Home
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="https://tailwindui.com/?ref=top"
                        className="hover:text-indigo-500 dark:hover:text-indigo-400"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        className="hover:text-indigo-500 dark:hover:text-indigo-400"
                        href="/blog"
                      >
                        Blog
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="text-indigo-500 dark:hover:text-indigo-400 cursor-pointer"
                        onClick={handleLogin}
                      >
                        Log In
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-indigo-500 dark:hover:text-indigo-400 cursor-pointer"
                        onClick={handleSignUp}
                      >
                        Sign Up
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="text-xl flex items-center flex-col pt-32 pb-10  px-4 sm:px-6 md:px-8">
            <div className="w-full text-center text-5xl font-bold">
              Welcome to BillBook
            </div>
            <span className="text-base font-normal pt-2 pb-6">
              Your personal expense tracker
            </span>
            <Button
              type={"submit"}
              className={
                "w-auto px-6 items-center flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={handleLogin}
              children={"Get Started"}
            />
          </div>
          <div className="flex items-center  flex-col pb-12">
            <img src={bg} alt="dashBoard" className="w-2/4"></img>
          </div>
          <div className="flex items-center py-20 flex-col bg-white">
            <h2 className="text-3xl w-full text-center font-bold">
              How it Works
            </h2>
            <span className="text-base font-normal py-2">
              Track and manage your expenses with ease
            </span>
            <div className="flex py-2">
              {/* Sidebar with Tabs */}

              {/* Tab Content */}
              <div className="w-3/4 p-4">
                <div className="text-gray-800 text-lg">
                  {tabs.find((tab) => tab.id === activeTab)?.content}
                </div>
              </div>
              <div className="w-5/6 p-4">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`block w-full text-left px-4 py-4 mb-2 rounded-md ${
                      activeTab === tab.id
                        ? "bg-indigo-200 text-indigo-600"
                        : "text-gray-800"
                    } hover:bg-indigo-100 hover:text-indigo-600`}
                  >
                    <div className="capitalize font-semibold">{tab.label}</div>
                    <div className="text-base">{tab.helpText}</div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="flex items-center py-20 flex-col">
            <div className="w-9/12">
              <div className="w-full text-center">
                <h2 className="text-3xl font-bold">Why Choose BillBook</h2>
                <span className="text-base font-normal py-2">
                  A tool designed to make expense tracking easy and efficient
                </span>
              </div>
              <div className="flex py-2 mt-10 justify-center flex-col">
                <div className="flex justify-center ">
                  <div className="p-6 bg-white w-80 rounded-md border border-indigo-500 relative">
                    <small
                      className="py-1 uppercase bg-indigo-500 rounded-full font-bold px-4 absolute"
                      style={{ top: "-13px", left: "29%" }}
                    >
                      Best Feature
                    </small>
                    <h2 className="font-bold text-xl pb-2">
                      {" "}
                      User-Friendly Interface
                    </h2>
                    <span className="">
                      Our interface is designed to be intuitive and easy to use,
                      making expense tracking a breeze
                    </span>
                  </div>
                  <div className="mx-4"> </div>
                  <div className="p-8 bg-white w-80 rounded-md ">
                    <h2 className="font-bold text-xl pb-2">
                      {" "}
                      Detailed Reports
                    </h2>
                    <span className="">
                      Generate detailed reports based on your selected criteria
                    </span>
                  </div>
                </div>
                <div className="w-full flex justify-center text-center py-10">
                  <Button
                    type={"submit"}
                    className={
                      "w-auto items-center flex px-6 justify-center rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                    onClick={handleLogin}
                    children={"Sign Up"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center py-20 flex-col bg-white">
            <div className="w-9/12">
              <div className="w-full text-center">
                <h2 className="text-3xl font-bold">Features</h2>
                <span className="text-base font-normal py-2">
                  Explore the powerful features of BillBook
                </span>
              </div>
              <div className="flex py-2 mt-4">
                <div className="p-4">
                  <div className="flex items-center">
                    <span className="rounded bg-red-300 w-4 h-4 rounded-full bg-red-300 w-8 h-8 flex items-center justify-center mr-2">
                      <i className="fa-solid fa-database text-sm"></i>
                    </span>
                    <h2 className="uppercase font-bold"> Easy Data Entry</h2>
                  </div>
                  <span className="pl-10 flex">
                    Enter your expenses in a simple and intuitive interface
                  </span>
                </div>
                <div className="p-4 mx-4">
                  <div className="flex items-center">
                    <span className="rounded bg-red-300 w-4 h-4 rounded-full bg-red-300 w-8 h-8 flex items-center justify-center mr-2">
                      <i className="fa-solid fa-database text-sm"></i>
                    </span>
                    <h2 className="uppercase font-bold"> Detailed Dashboard</h2>
                  </div>
                  <span className="pl-10 flex">
                    Get a detailed view of your expenses by month, year, or year
                    to date
                  </span>
                </div>
                <div className="p-4">
                  <div className="flex items-center">
                    <span className="rounded bg-red-300 w-4 h-4 rounded-full bg-red-300 w-8 h-8 flex items-center justify-center mr-2">
                      <i className="fa-solid fa-sheet-plastic text-sm"></i>
                    </span>
                    <h2 className="uppercase font-bold">Custom Reports</h2>
                  </div>
                  <span className="pl-10 flex">
                    Generate and download reports based on your selected start
                    date, end date, and type of payment
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center py-20 flex-col ">
            <div className="w-9/12">
              <div className="w-full text-center">
                <h2 className="text-3xl font-bold">Choose Your Plan</h2>
                <span className="text-base font-normal py-2">
                  We offer flexible pricing options to suit your needs
                </span>
              </div>
              <div className="flex py-2 mt-10 justify-center flex-col">
                <div className="flex justify-center ">
                  <div className="p-6 bg-white w-80 rounded-md shadow-lg text-center border border-indigo-500 relative">
                    <h2 className="font-bold text-xl pb-2">
                      Unlimited Access,{" "}
                      <span className="text-indigo-700	">Forever Free</span>
                    </h2>
                    <span className="">
                      Enjoy all features at no cost—no hidden fees, no
                      subscriptions. Track your expenses without spending a
                      penny.
                    </span>
                  </div>
                </div>
                <div className="w-full flex justify-center text-center py-10">
                  <Button
                    type={"submit"}
                    className={
                      "w-auto items-center flex px-6 justify-center rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                    onClick={handleLogin}
                    children={"Sign Up"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center py-20 flex-col bg-white">
            <div className="w-9/12">
              <div className="w-full text-center">
                <h2 className="text-3xl font-bold">
                  Ready to take control of your expenses?
                </h2>
                <span className="text-base font-normal py-2">
                  Sign up for BillBook today
                </span>
              </div>
              <div className="flex py-2 mt-10 justify-center flex-col">
                <div className="w-full flex justify-center text-center">
                  <Button
                    type={"submit"}
                    className={
                      "w-auto items-center flex px-6 justify-center rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                    onClick={handleLogin}
                    children={"Sign Up"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center py-20 flex-col">
            <div className="w-9/12">
              <div className="w-full text-center">
                <h2 className="text-3xl font-bold">
                  Get Started with BillBook
                </h2>
                <span className="text-base font-normal py-2">
                  Choose a feature to learn more
                </span>
              </div>
              <div className="w-full flex justify-center text-center py-10">
                <Button
                  type={"submit"}
                  className={
                    "w-auto items-center flex px-6 justify-center rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  }
                  onClick={handleLogin}
                  children={"Read More"}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center py-20 flex-col bg-white">
            <div className="w-9/12">
              <div className="w-full text-center">
                <h2 className="text-3xl font-bold">What Our Users Say</h2>
                <span className="text-base font-normal py-2">
                  Hear from our satisfied users
                </span>
              </div>
              <div className="flex py-2 justify-center flex-col">
                <div className="flex justify-center ">
                  <section className="bg-white dark:bg-gray-900">
                    <div className="max-w-screen-xl px-4 py-2 mx-auto text-center lg:py-8 lg:px-6">
                      <figure className="max-w-screen-md mx-auto">
                        <svg
                          className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                            fill="currentColor"
                          />
                        </svg>
                        <blockquote>
                          <p className="text-2xl font-medium text-gray-900 dark:text-white">
                            "I can’t believe this app is free. It has all the
                            features I need, and the interface is super
                            user-friendly!"
                          </p>
                        </blockquote>
                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                          <img
                            className="w-6 h-6 rounded-full"
                            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png"
                            alt="profile picture"
                          />
                          <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">
                              Micheal Gough
                            </div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                              CEO at Google
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                    </div>
                  </section>
                </div>
                <div className="w-full flex justify-center text-center py-10">
                  <Button
                    type={"submit"}
                    className={
                      "w-auto items-center flex px-6 justify-center rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                    onClick={handleLogin}
                    children={"Read More"}
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className="flex items-center py-20 flex-col ">
            <div className="w-9/12 text-center">
              © 2022 BillBook. All rights reserved.
            </div>
          </footer>
        </div>
      </header>
    </div>
  );
};

export { Home };
