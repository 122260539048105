import React, { useState, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Cookies from "js-cookie";
import { useGetBrandDetails, useGetPaymentList } from "../data/data";
import Loader from "../components/Loader/loader";
import { DeletePayment } from "../modules/deletePayment";
import { AddPayment } from "../modules/addPayment";
import { debounce } from "lodash";
import { ShowPaymentDetails } from "./showPaymentDetails";

const PaymentList = () => {
  const org_id = Cookies.get("id");
  const [activeTab, setActiveTab] = useState("upcoming");
  const [showDelete, setShowDelete] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectEditData, setSelectEditData] = useState({
    payment_name: "",
    amount: "",
    due_date: "",
    notes: "",
    file_base64: "",
    id: "",
    org_id: "",
  });
  const [selectDeletedId, setSelectDeletedId] = useState("");

  const {
    data: brandData,
    isLoading: brandLoading,
    isFetching: brandFetching,
  } = useGetBrandDetails({
    action: "getBrandingInfo",
    org_id,
  });
  const currency =
    brandData?.data?.[0]?.currency !== ""
      ? brandData?.data?.[0]?.currency === "usd"
        ? "$"
        : "₹"
      : "$";

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Name",
      field: "payment_name",
      width: 220,
      cellRenderer: (params: any) => {
        const data = params?.data;
        return (
          <span
            className="text-sm capitalize text-indigo-600 cursor-pointer"
            onClick={() => handleDetails(data)}
          >
            {params?.data?.payment_name}
          </span>
        );
      },
    },
    {
      headerName: "Mode",
      field: "payment_mode",
      width: 130,
      cellRenderer: (params: any) => {
        return (
          <span className="text-sm capitalize">
            {params?.data?.payment_mode}
          </span>
        );
      },
    },
    {
      headerName: "Type",
      field: "payment_type",
      width: 130,
      cellRenderer: (params: any) => {
        const status = params?.data?.payment_type;

        if (!status) {
          return null; // Do not render anything if status is empty
        }

        let statusClass = "";
        let statusText = "";

        if (status.includes("credited")) {
          statusClass = "bg-green-50 text-green-700 ring-green-600/20";
          statusText = "Credited";
        } else if (status.includes("debited")) {
          statusClass = "bg-red-50 text-red-700 ring-red-600/20";
          statusText = "Debited";
        } else if (status.includes("other")) {
          statusClass = "bg-gray-50 text-gray-700 ring-gray-600/20";
          statusText = "Other";
        }

        return (
          <span
            className={`text-sm capitalize inline-flex items-center rounded px-2 py-1  font-medium ring-1 ring-inset ${statusClass}`}
          >
            {statusText}
          </span>
        );
      },
    },
    {
      headerName: "Status",
      field: "payment_status",
      width: 130,
      cellRenderer: (params: any) => {
        const status = params?.data?.payment_status;

        if (!status) {
          return null; // Do not render anything if status is empty
        }

        let statusClass = "";
        let statusText = "";

        if (status.includes("paid")) {
          statusClass = "bg-green-50 text-green-700 ring-green-600/20";
          statusText = "Paid";
        } else if (status.includes("unPaid")) {
          statusClass = "bg-red-50 text-red-700 ring-red-600/20";
          statusText = "Unpaid";
        } else if (status.includes("other")) {
          statusClass = "bg-gray-50 text-gray-700 ring-gray-600/20";
          statusText = "Other";
        }

        return (
          <span
            className={`text-sm capitalize inline-flex items-center rounded px-2 py-1  font-medium ring-1 ring-inset ${statusClass}`}
          >
            {statusText}
          </span>
        );
      },
    },

    {
      headerName: "Amount",
      field: "amount",
      width: 130,
      cellRenderer: (params: any) => {
        return (
          <span className="text-sm capitalize">
            {currency}
            {params?.data?.amount}
          </span>
        );
      },
    },
    {
      headerName: "Due Date",
      field: "due_date",
      width: 130,
      sortable: true,
      cellRenderer: (params: any) => {
        const dueDate = new Date(params.data.due_date);
        const currentDate = new Date();

        // Reset time to compare only the date part
        dueDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const isPastOrSameDay = dueDate <= currentDate;
        const colorClass = isPastOrSameDay ? "text-red-700" : "text-green-700";

        return (
          <span className={`text-sm capitalize ${colorClass}`}>
            {params.data.due_date}
          </span>
        );
      },
    },

    {
      headerName: "Notes",
      field: "notes",
      flex: 1,
      cellRenderer: (params: any) => {
        return (
          <span className="text-sm capitalize">{params?.data?.notes}</span>
        );
      },
    },
    {
      headerName: "Attachment",
      field: "",
      width: 130,
      cellRenderer: (params: any) => {
        return params.data?.file_base64 !== null &&
          params.data?.file_base64 !== "" ? (
          // <img
          //   src={`data:image/png;base64,${params?.data?.file_base64}`}
          //   alt="Attachment"
          //   style={{ width: "50px", height: "50px" }}
          //   className="my-2"
          // />
          1
        ) : (
          <span>--</span>
        );
      },
    },
    {
      headerName: "Actions",
      field: "",
      width: 100,
      cellRenderer: (params: any) => {
        const ID = params.data.id;
        return (
          <span className="flex py-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4 cursor-pointer"
              onClick={() => handleEdit(params?.data)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4 cursor-pointer"
              onClick={() => handleDelete(ID)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </span>
        );
      },
    },
  ]);

  const handleSearchQuery = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const {
    data: rowData,
    isLoading,
    isFetching,
    refetch,
  } = useGetPaymentList("getPaymentsList", org_id, type, searchQuery);

  const handleDelete = (id: string) => {
    setSelectDeletedId(id);
    setShowDelete(true);
  };

  const handleEdit = (data: any) => {
    setSelectEditData(data);
    setShowEdit(true);
  };
  function handleClose() {
    setShowDelete(false);
  }
  function handleCloseEdit() {
    setShowEdit(false);
  }

  useEffect(() => {
    if (activeTab) {
      setType("");
    }
  }, [activeTab]);

  function handleDetails(data: any) {
    setSelectedData(data);
    setShowDetails(true);
  }
  function handleCloseDetails() {
    setShowDetails(false);
  }
  return (
    <div>
      <h1 className="font-bold text-lg mb-4">Payments</h1>
      <div className="w-full mb-4">
        <div className="flex space-x-2">
          <button
            className={`py-2 px-4 text-sm font-medium transition duration-300 rounded-md  ${
              activeTab === "upcoming" ? "bg-white shadow" : "hover:bg-white"
            }`}
            onClick={() => setActiveTab("upcoming")}
          >
            Upcoming Payments
          </button>
          <button
            className={`py-2 px-4 text-sm font-medium transition duration-300 rounded-md ${
              activeTab === "recent" ? "bg-white shadow" : "hover:bg-white"
            }`}
            onClick={() => setActiveTab("recent")}
          >
            Recent Payments
          </button>
        </div>
      </div>

      <div className="transition duration-300 w-full">
        {activeTab === "upcoming" ? (
          <div className="animate-fadeIn bg-white w-100 shadow-sm rounded-md">
            <div className="flex justify-end">
              <div className="m-2 w-30">
                <div className=" w-full">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={searchQuery}
                    onChange={(e) => handleSearchQuery(e.target.value)}
                    placeholder="Search By Name"
                  />
                </div>
              </div>
              <div className="m-2 w-30">
                <div className=" w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={""}>Select Type</option>
                    <option value={"credited"}>Credited</option>
                    <option value={"debited"}>Debited</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              className="ag-theme-quartz w-full h-96 shadow-sm bg-white rounded-md"
              style={{ height: 500 }}
            >
              {isLoading || isFetching ? (
                <span className="flex justify-center items-center h-full">
                  <Loader withWhiteBg={true} size="medium" />
                </span>
              ) : (
                <AgGridReact
                  rowData={rowData}
                  columnDefs={colDefs}
                  rowHeight={40}
                  animateRows={true}
                  domLayout={"normal"}
                  pagination={true}
                  paginationPageSize={20}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="animate-fadeIn bg-white w-100 shadow-sm rounded-md">
            <div className="flex justify-end">
              <div className="m-2 w-30">
                <div className=" w-full">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={searchQuery}
                    onChange={(e) => handleSearchQuery(e.target.value)}
                    placeholder="Search By Name"
                  />
                </div>
              </div>
              <div className="m-2 w-30">
                <div className=" w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={""}>Select Type</option>
                    <option value={"credited"}>Credited</option>
                    <option value={"debited"}>Debited</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              className="ag-theme-quartz w-full h-96 shadow-sm bg-white rounded-md"
              style={{ height: 500 }}
            >
              {isLoading || isFetching ? (
                <span className="flex justify-center items-center h-full">
                  <Loader withWhiteBg={true} size="medium" />
                </span>
              ) : (
                <AgGridReact
                  rowData={
                    rowData.filter((row: any) =>
                      ["paid", "received"].includes(row?.payment_status)
                    ) ?? []
                  }
                  columnDefs={colDefs}
                  rowHeight={40}
                  animateRows={true}
                  domLayout={"normal"}
                  pagination={true}
                  paginationPageSize={20}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showDelete ? (
        <DeletePayment
          selectDeletedId={selectDeletedId}
          closeDialog={handleClose}
        />
      ) : null}
      {showEdit ? (
        <AddPayment
          closeDialog={handleCloseEdit}
          selectEditData={selectEditData}
        />
      ) : null}
      {showDetails ? (
        <ShowPaymentDetails
          closeDialog={handleCloseDetails}
          selectedData={selectedData}
        />
      ) : null}
    </div>
  );
};

export { PaymentList };
