import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, NavLink, useNavigate } from "react-router-dom";
//import { AddTask } from "../modules/addTask";
import { Dialog } from "../components/Dialog/dialog";

import { useCookies } from "react-cookie";

const LeftNav = (onToggle: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [onboardDetails, setOnboardDetails] = useState<any>([]);
  const user_id = Cookies.get("id");
  const user_name = Cookies.get("name");
  const user_type = Cookies.get("user_type");

  function handleDialog() {
    setOpenDialog((current) => !current);
  }

  const handleCloseDialog = (e: any) => {
    setOpenDialog(e);
  };

  return (
    <aside
      className="fixed top-0 left-0 w-44 h-screen transition-transform -translate-x-full sm:translate-x-0 shadow-sm"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 overflow-y-auto bg-white pt-16">
        <ul className="space-y-2 text-sm mt-4 leftNav">
          <li className="leading-8">
            <NavLink
              to="/portal/dashboard"
              className="w-full text-sm leading-4 flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              Dashboard
            </NavLink>
          </li>
          <li className="leading-8">
            <NavLink
              to="/portal/payments"
              className="w-full text-sm leading-4 flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Payments
            </NavLink>
          </li>
          <li className="leading-8">
            <NavLink
              to="/portal/reports"
              className="w-full text-sm leading-4 flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
              Reports
            </NavLink>
          </li>
          <li className="leading-8">
            <NavLink
              to="/portal/settings"
              className="w-full text-sm leading-4 flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export { LeftNav };
