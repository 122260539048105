import React, { useState } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import { BASE_URL } from "../api/api";
import { Button } from "../components/Button/button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useCreatePayment, useUpdatePayment } from "../data/data";

type createProps = {
  closeDialog: any;
  selectEditData?: any;
};

type PaymentPayload = {
  action: string;
  paymentName: any;
  amount: any;
  payment_type: string;
  payment_mode: string;
  payment_status: string;
  dueDate: any;
  notes: any;
  fileBase64: string;
  org_id: string | undefined;
  paymentId?: any;
};

const AddPayment = ({ closeDialog, selectEditData }: createProps) => {
  const user_id = Cookies.get("id");
  const [isBtnLoad, setIsBtnLoad] = useState(false);
  const [paymentName, setPaymentName] = useState(
    selectEditData?.payment_name ?? ""
  );
  const [amount, setAmount] = useState(selectEditData?.amount ?? "");
  const [type, setType] = useState(selectEditData?.payment_type ?? "");
  const [mode, setMode] = useState(selectEditData?.payment_type ?? "");
  const [paymentStatus, setPaymentStatus] = useState(
    selectEditData?.payment_status ?? ""
  );
  const [dueDate, setDueDate] = useState(selectEditData?.due_date ?? "");
  const [notes, setNotes] = useState(selectEditData?.notes ?? "");
  const [fileBase64, setFileBase64] = useState(
    selectEditData?.file_base64 ?? ""
  );
  const [error, setError] = useState<string | null>(null);

  function handleClose() {
    closeDialog(false);
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result
            .toString()
            .replace("data:", "")
            .replace(/^.+,/, "");
          setFileBase64(base64String);
          setError(null); // Clear any previous errors
        } else {
          setError("Failed to read file content.");
        }
      };
      reader.onerror = (error) => {
        setError("Failed to read file");
        console.error("Error reading file: ", error);
      };
      reader.readAsDataURL(file);
    } else {
      setError("No file selected");
    }
  };

  const { mutate } = useCreatePayment();
  const { mutate: update } = useUpdatePayment();

  const handleCreatePayment = async (e: any) => {
    e.preventDefault();
    setIsBtnLoad(true);
    const payload: PaymentPayload = {
      action: "addPayment",
      paymentName: paymentName,
      amount: amount,
      payment_type: type,
      payment_mode: mode,
      payment_status: paymentStatus,
      dueDate: dueDate,
      notes: notes,
      fileBase64: fileBase64,
      org_id: user_id,
    };
    mutate(payload, {
      onSuccess: () => {
        setIsBtnLoad(false);
        setTimeout(() => {
          closeDialog(false);
        }, 300);
      },
      onError: () => {
        setIsBtnLoad(false);
      },
      onSettled: () => {
        setIsBtnLoad(false);
      },
    });
  };
  const handleUpdatePayment = async (e: any) => {
    e.preventDefault();
    setIsBtnLoad(true);
    const payload: PaymentPayload = {
      action: "editPayment",
      paymentName: paymentName,
      amount: amount,
      payment_type: type,
      payment_mode: mode,
      payment_status: paymentStatus,
      dueDate: dueDate,
      notes: notes,
      fileBase64: fileBase64,
      org_id: user_id,
      paymentId: selectEditData?.id,
    };
    update(payload, {
      onSuccess: () => {
        setIsBtnLoad(false);
        setTimeout(() => {
          closeDialog(false);
        }, 300);
      },
    });
  };

  const isValid = () => {
    return (
      paymentName !== "" &&
      amount !== "" &&
      dueDate !== "" &&
      type !== "" &&
      mode !== "" &&
      paymentStatus !== ""
    );
  };

  return (
    <div
      style={{ backgroundColor: "#00000073" }}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
    >
      <div className="relative p-4 w-5/12 max-h-full">
        <div className="relative bg-white rounded-md shadow p-4">
          <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10">
              {selectEditData !== undefined ? "Edit Payment" : "Add Payment"}
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.2}
              stroke="currentColor"
              className="size-5 cursor-pointer"
              onClick={handleClose}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="flex">
            <label
              htmlFor="email"
              className="block text-xs text-gray-900 w-2/12 text-end leading-8 pe-2"
            ></label>
            <div className="w-full px-6"></div>
          </div>
          <form className="">
            <div className="mt-4 grid grid-cols-1 gap-x-3 gap-y-2 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900  text-start leading-8 pe-2"
                >
                  Payment Name
                </label>

                <div className=" w-full">
                  <input
                    type="text"
                    defaultValue={paymentName}
                    onChange={(e) => setPaymentName(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter payment name"
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900  text-start leading-8 pe-2"
                >
                  Amount
                </label>

                <div className=" w-full">
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter amount"
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900  text-start leading-8 pe-2"
                >
                  Payment Type
                </label>

                <div className=" w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={""}>Select Type</option>
                    <option value={"credited"}>Credited</option>
                    <option value={"debited"}>Debited</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900  text-start leading-8 pe-2"
                >
                  Payment Mode
                </label>

                <div className=" w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                  >
                    <option value={""}>Select Mode</option>
                    <option value={"upi"}>UPI</option>
                    <option value={"cash"}>Cash</option>
                    <option value={"cheque"}>Cheque</option>
                    <option value={"others"}>Others</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900  text-start leading-8 pe-2"
                >
                  Payment Status
                </label>

                <div className=" w-full">
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  >
                    <option value={""}>Select Status</option>
                    <option value={"paid"}>Paid</option>
                    <option value={"unPaid"}>UnPaid</option>
                    <option value={"other"}>Other</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xs text-gray-900  text-start leading-8 pe-2"
                >
                  Due Date
                </label>

                <div className=" w-full">
                  <input
                    type="date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter due date"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-xs text-gray-900  text-start leading-8 pe-2"
              >
                Notes
              </label>

              <div className="w-full">
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter notes"
                  required
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <label className="block text-xs text-gray-900  text-start leading-8 pe-2">
                Upload File
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {fileBase64?.length ? (
                <img
                  src={`data:image/png;base64,${fileBase64}`}
                  alt="Attachment"
                  style={{ height: "50px" }}
                  className="my-2"
                />
              ) : null}
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <footer className="py-2 flex justify-end mt-4">
              <Button
                className="items-center flex  justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-slate-700 "
                type="button"
                onClick={handleClose}
                children={"Cancel"}
              />
              <Button
                type={"submit"}
                isButtonLoaded={isBtnLoad}
                className={
                  "items-center flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={
                  selectEditData !== undefined
                    ? handleUpdatePayment
                    : handleCreatePayment
                }
                disabled={!isValid()}
                children={selectEditData !== undefined ? "Update" : "Save"}
              />
            </footer>
          </form>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export { AddPayment };
