import React, { useCallback, useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { TopNav } from "./TopNav";
import { LeftNav } from "./LeftNav";
import { Toaster } from "react-hot-toast";

const Portal = () => {
  const navigate = useNavigate();
  const user_Id = Cookies.get("id");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  }, [setIsLoading]);

  useEffect(() => {
    if (!user_Id) {
      setIsLoading(true);
      return navigate("/");
    }
  }, [navigate]);

  return (
    <div className="wrapper bg-slate-100 h-screen overflow-hidden">
      <LeftNav />
      {isLoading ? (
        <div className="main dashBoardLayout">
          <TopNav />
          <main className="content ps-40 w-full h-screen">
            <div className="container-fluid ps-5 h-screen overflow-auto">
              <div className="row pt-11 text-xs px-4 mt-4">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="w-screen h-screen justify-center items-center">
          Loading
        </div>
      )}
      <Toaster />
    </div>
  );
};

export { Portal };
