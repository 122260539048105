import React, { useState } from "react";
import { useGetBrandDetails, useGetPaymentList } from "../../data/data";
import Cookies from "js-cookie";
import { useUser } from "../../context/UserContext";

const TotalAnalytics = () => {
  const org_id = Cookies.get("id");
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, isFetching, refetch } = useGetPaymentList(
    "getPaymentsList",
    org_id,
    type,
    searchQuery
  );
  const {
    data: brandData,
    isLoading: brandLoading,
    isFetching: brandFetching,
  } = useGetBrandDetails({
    action: "getBrandingInfo",
    org_id,
  });
  let totalAmount = 0;
  let paidAmount = 0;
  let creditedAmount = 0;
  const currency =
    brandData?.data?.[0]?.currency !== ""
      ? brandData?.data?.[0]?.currency === "usd"
        ? "$"
        : "₹"
      : "$";
  data?.forEach((data: any) => {
    const amount = parseFloat(data.amount);
    totalAmount += amount;
    if (data.payment_type === "debited") {
      paidAmount += amount;
    }
    if (data.payment_type === "credited") {
      creditedAmount += amount;
    }
  });
  const paidPercentage =
    totalAmount > 0 ? (creditedAmount - paidAmount).toFixed(2) : 0;
  console.log("currency", currency);
  return (
    <div className="">
      <h2 className="font-semibold text-base mb-4">Overview</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          className={`rounded-lg shadow-md flex flex-col justify-between relative overflow-hidden`}
          style={{ background: "#E0E6FA" }}
        >
          <div className="p-4 rounded-md" style={{ background: "#CED7F7" }}>
            <div className="flex justify-between items-center">
              <span className="text-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
                  />
                </svg>
              </span>
            </div>
            <h3 className="text-base font-normal mt-1">Balance</h3>
          </div>
          <div className="p-4">
            <p className="text-2xl font-bold">
              {currency}
              {paidPercentage}
            </p>
            {/* <p className={`text-sm`}>+12% ↗</p> */}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 absolute"
            style={{
              width: 100,
              height: 100,
              bottom: -40,
              transform: "rotate(-20deg)",
              opacity: 0.05,
              right: -30,
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
            />
          </svg>
        </div>
        <div
          className={`rounded-lg shadow-md flex flex-col justify-between relative overflow-hidden`}
          style={{ background: "#E2D6F8" }}
        >
          <div className="p-4 rounded-md" style={{ background: "#D4C6F5" }}>
            <div className="flex justify-between items-center">
              <span className="text-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                  />
                </svg>
              </span>
            </div>
            <h3 className="text-base font-normal mt-1">Income</h3>
          </div>
          <div className="p-4">
            <p className="text-2xl font-bold">
              {" "}
              {currency}
              {creditedAmount.toFixed(2)}
            </p>
            {/* <p className={`text-sm`}>+12% ↗</p> */}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 absolute"
            style={{
              width: 100,
              height: 100,
              bottom: -40,
              transform: "rotate(-20deg)",
              opacity: 0.05,
              right: -30,
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
            />
          </svg>
        </div>
        <div
          className={`rounded-lg shadow-md flex flex-col justify-between relative overflow-hidden`}
          style={{ background: "#D9F1DE" }}
        >
          <div className="p-4 rounded-md" style={{ background: "#BDE5C4" }}>
            <div className="flex justify-between items-center">
              <span className="text-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                  />
                </svg>
              </span>
            </div>
            <h3 className="text-base font-normal mt-1">Expenses</h3>
          </div>
          <div className="p-4">
            <p className="text-2xl font-bold">
              {" "}
              {currency}
              {paidAmount.toFixed(2)}
            </p>
            {/* <p className={`text-sm`}>+12% ↗</p> */}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 absolute"
            style={{
              width: 100,
              height: 100,
              bottom: -40,
              transform: "rotate(-20deg)",
              opacity: 0.05,
              right: -30,
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export { TotalAnalytics };
