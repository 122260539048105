import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../api/api";
import Cookies from "js-cookie";
import companyLogo from "../../assets/logo.png";
import { Button } from "../../components/Button/button";
import { useGetBrandDetails } from "../../data/data";
import Roboto from "../../font/Roboto-base64"; // Assume you have the base64 encoded font

const ReportsPage = () => {
  const org_id = Cookies.get("id");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isBtnLoad, setIsBtnLoad] = useState(false);
  const [logoBase64, setLogoBase64] = useState<any>("");
  const [logoWidth, setLogoWidth] = useState(0);

  const { data: brandData, isLoading: brandLoading } = useGetBrandDetails({
    action: "getBrandingInfo",
    org_id,
  });
  useEffect(() => {
    const toBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const fetchLogo = async () => {
      const logoUrl = brandData?.data?.[0]?.BrandingLogo
        ? `data:image/png;base64,${brandData.data[0].BrandingLogo}`
        : companyLogo;
      const response = await fetch(logoUrl);
      const blob = await response.blob();
      const base64 = await toBase64(blob);
      setLogoBase64(base64);

      // Create an image element to calculate the width and height
      const img: any = new Image();
      img.src = base64;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const calculatedWidth = aspectRatio * 10; // 20 is the fixed height
        setLogoWidth(calculatedWidth);
      };
    };

    fetchLogo();
  }, [brandData]);
  console.log(logoBase64);
  const isValid = () => startDate !== "" && endDate !== "" && type !== "";

  const capitalizeFirstLetter = (string: any) =>
    string.replace(/\b\w/g, (char: any) => char.toUpperCase());

  const currency = brandData?.data?.[0]?.currency === "usd" ? "$" : "₹";

  const generatePDF = async () => {
    setIsBtnLoad(true);
    try {
      const response = await axios.post(BASE_URL, {
        action: "getPaymentsList",
        org_id,
        filter: type,
        dateRangeStart: startDate,
        dateRangeEnd: endDate,
      });

      const payments = response.data;
      const doc = new jsPDF();
      doc.addFileToVFS("Roboto-SemiBold.ttf", Roboto);
      doc.addFont("Roboto-SemiBold.ttf", "Roboto", "normal");
      doc.setFont("Roboto");
      doc.setFontSize(10);
      const date = new Date().toLocaleDateString();

      // Add company logo
      if (logoBase64) {
        try {
          doc.addImage(logoBase64, "PNG", 10, 7, logoWidth, 10);
        } catch (error) {
          console.error("Error adding image:", error);
        }
      }

      //doc.text("Bill Remainder", 10, 20);
      doc.text(`Report from ${startDate} to ${endDate}`, 127, 16);
      doc.text(`Generated on: ${date}`, 150, 10);

      doc.text("Name", 10, 50);
      doc.text("Amount", 80, 50);
      doc.text("Due Date", 120, 50);
      doc.text("Status", 150, 50);
      doc.text("Type", 170, 50);
      doc.line(10, 55, 200, 55);

      let yOffset = 65;
      const pageHeight = doc.internal.pageSize.height;

      const marginBottom = 10; // Margin at the bottom of the page
      let totalAmount = 0;
      payments.forEach((payment: any) => {
        if (yOffset > pageHeight - marginBottom) {
          doc.addPage();

          yOffset = 20; // Reset yOffset for the new page

          doc.setFont("Roboto", "normal");

          doc.text("Name", 10, yOffset);

          doc.text("Amount", 80, yOffset);

          doc.text("Due Date", 120, yOffset);

          doc.text("Status", 150, yOffset);

          doc.text("Type", 170, yOffset);

          doc.line(10, yOffset + 5, 200, yOffset + 5);

          yOffset += 10;
        }
        doc.text(capitalizeFirstLetter(payment.payment_name), 10, yOffset);
        doc.text(`${currency} ${payment.amount}`, 80, yOffset);
        doc.text(payment.due_date, 120, yOffset);
        doc.text(
          payment.payment_status
            ? capitalizeFirstLetter(payment.payment_status)
            : "--",
          150,
          yOffset
        );
        doc.text(
          payment.payment_type
            ? capitalizeFirstLetter(payment.payment_type)
            : "--",
          170,
          yOffset
        );
        yOffset += 10;
        totalAmount += parseFloat(payment.amount);
      });

      // Add line to separate total amount
      if (yOffset > pageHeight - marginBottom) {
        doc.addPage();
        yOffset = 20; // Reset yOffset for the new page
      }

      yOffset += 0; // Add some space before the total amount
      doc.line(10, yOffset, 200, yOffset);
      yOffset += 10; // Move yOffset down after the line

      // Add total amount

      if (yOffset > pageHeight - marginBottom) {
        doc.addPage();

        yOffset = 20; // Reset yOffset for the new page
      }

      doc.setFont("Roboto", "normal");

      doc.text("Total", 11, yOffset);

      doc.text(`${currency} ${totalAmount.toFixed(2)}`, 80, yOffset);

      doc.setFont("Roboto", "normal"); // Reset to normal font after bold

      doc.save(`payment_report_${startDate}_to_${endDate}.pdf`);
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      setIsBtnLoad(false);
    }
  };

  return (
    <div>
      <h1 className="font-bold text-lg mb-4">Download Reports</h1>
      <div className="animate-fadeIn bg-white w-100 shadow-sm rounded-md p-4">
        <div className="pt-4 w-1/4">
          <div className="sm:col-span-3">
            <label
              htmlFor="type"
              className="block text-xs text-gray-900 text-start leading-8 pe-2"
            >
              Payment Type
            </label>
            <div className="w-full">
              <select
                id="type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value={""}>Select Type</option>
                <option value={"credited"}>Credited</option>
                <option value={"debited"}>Debited</option>
              </select>
            </div>
          </div>
          <div className="my-2">
            <label
              htmlFor="startDate"
              className="block text-xs text-gray-900 text-start leading-8 pe-2"
            >
              Start Date
            </label>
            <div className="w-full">
              <input
                id="startDate"
                type="date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </div>
          <div className="my-2">
            <label
              htmlFor="endDate"
              className="block text-xs text-gray-900 text-start leading-8 pe-2"
            >
              End Date
            </label>
            <div className="w-full">
              <input
                id="endDate"
                type="date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <footer className="py-2 flex justify-end mt-4">
            <Button
              type="submit"
              isButtonLoaded={isBtnLoad}
              className={
                "items-center flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={generatePDF}
              children={"Download PDF"}
              disabled={!isValid()}
            />
          </footer>
        </div>
      </div>
    </div>
  );
};

export { ReportsPage };
