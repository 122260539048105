import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetBrandDetails } from "../data/data";
import Cookies from "js-cookie";
import axios from "axios";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const org_id = Cookies.get("id");
  const [userDetails, setUserDetails] = useState(null);
  const {
    data: brandData,
    isLoading,
    isFetching,
    refetch,
  } = useGetBrandDetails({
    action: "getBrandingInfo",
    org_id,
  });

  useEffect(() => {
    if (brandData) {
      setUserDetails(brandData?.data?.[0]);
    }
  }, [brandData]);

  const updateBranding = async (brandingData) => {
    try {
      const response = await axios.post("https://your-api-endpoint.com", {
        action: "updateBranding",
        org_id,
        ...brandingData,
      });
      if (response.status === 200) {
        await refetch(); // Refetch the data after successful update
      } else {
        throw new Error("Update failed!");
      }
    } catch (error) {
      console.error("Error updating branding:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{ userDetails, isLoading, isFetching, updateBranding }}
    >
      {children}
    </UserContext.Provider>
  );
};
