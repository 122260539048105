import React, { useState } from "react";
import { Button } from "../components/Button/button";
import Cookies from "js-cookie";
import { useAddUserDetails, useGetUserDetails } from "../data/data";
import toast, { Toaster } from "react-hot-toast";

type PaymentPayload = {
  action: string;
  name: any;
  mobile: any;
  profile_pic: string;
  org_id: string | undefined;
  created_at?: string | undefined;
};

const Profile = (selectEditData?: any) => {
  const user_id = Cookies.get("id");
  const { data, isLoading, isFetching } = useGetUserDetails({
    action: "getUserDetails",
    org_id: user_id,
  });

  const [isBtnLoad, setIsBtnLoad] = useState(false);

  const [paymentName, setPaymentName] = useState(data?.NAME ?? "");
  const [mobileNum, setMobileNum] = useState(data?.mobile ?? "");
  const [fileBase64, setFileBase64] = useState(data?.profile_pic ?? "");
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result
            .toString()
            .replace("data:", "")
            .replace(/^.+,/, "");
          setFileBase64(base64String);
          setError(null); // Clear any previous errors
        } else {
          setError("Failed to read file content.");
        }
      };
      reader.onerror = (error) => {
        setError("Failed to read file");
        console.error("Error reading file: ", error);
      };
      reader.readAsDataURL(file);
    } else {
      setError("No file selected");
    }
  };

  const { mutate } = useAddUserDetails();

  const handleAddDetails = async (e: any) => {
    e.preventDefault();
    setIsBtnLoad(true);
    const payload = {
      action: "updateUserDetails",
      name: paymentName,
      mobile: mobileNum,
      profile_pic: fileBase64,
      org_id: user_id,
    };
    mutate(payload, {
      onSuccess: () => {
        setIsBtnLoad(false);
        toast.success("Payment Added Successfully!");
      },
      onError: () => {
        setIsBtnLoad(false);
        toast.error("Error Updating Data");
      },
    });
  };

  const handleUpdateDetails = async (e: any) => {};
  return (
    <div>
      <h1 className="font-bold text-lg mb-4">Profile</h1>
      <div className="animate-fadeIn bg-white w-100 shadow-sm rounded-md p-4">
        <form className="pt-4 w-1/4">
          <div className="my-2">
            <label
              htmlFor="email"
              className="block text-xs text-gray-900  text-start leading-8 pe-2"
            >
              Name
            </label>

            <div className=" w-full">
              <input
                type="text"
                defaultValue={paymentName}
                onChange={(e) => setPaymentName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter payment name"
                required
              />
            </div>
          </div>
          <div className="my-2">
            <label
              htmlFor="email"
              className="block text-xs text-gray-900  text-start leading-8 pe-2"
            >
              Mobile
            </label>

            <div className=" w-full">
              <input
                type="number"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter amount"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-gray-900  text-start leading-8 pe-2">
              Profile Pic
            </label>
            <div className="flex">
              <input
                type="file"
                onChange={handleFileChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {fileBase64?.length ? (
                <img
                  src={`data:image/png;base64,${fileBase64}`}
                  alt="Attachment"
                  className="m-2 w-10 h-10 rounded-full"
                />
              ) : null}
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <footer className="py-2 flex justify-end mt-4">
            <Button
              type={"submit"}
              isButtonLoaded={isBtnLoad}
              className={
                "items-center flex  justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={handleAddDetails}
              children={"Save"}
            />
          </footer>
        </form>
      </div>
      <Toaster />
    </div>
  );
};
export { Profile };
