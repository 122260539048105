import Cookies from "js-cookie";
import React, { useMemo, useState } from "react";
import { useGetPaymentList } from "../../data/data";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import Loader from "../../components/Loader/loader";
import moment from "moment";

const BarChartByYear = () => {
  const user_id = Cookies.get("id");
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const { data, isLoading, isFetching, refetch } = useGetPaymentList(
    "getPaymentsList",
    user_id,
    type,
    searchQuery
  );

  const filteredData = useMemo(() => {
    return data?.filter(
      (item: any) =>
        item.payment_status === "paid" &&
        moment(item.due_date, "YYYY-MM-DD").format("YYYY") === selectedYear
    );
  }, [data, selectedYear]);

  const processedData = useMemo(() => {
    const result: any = [];
    filteredData?.forEach((item: any) => {
      const date = item.due_date;
      const existingItem = result.find((d: any) => d.due_date === date);
      if (existingItem) {
        if (item.payment_type === "credited") {
          existingItem.credited += parseFloat(item.amount);
        } else if (item.payment_type === "debited") {
          existingItem.debited += parseFloat(item.amount);
        }
        existingItem.payment_names.push(item.payment_name);
      } else {
        result.push({
          due_date: date,
          credited:
            item.payment_type === "credited" ? parseFloat(item.amount) : 0,
          debited:
            item.payment_type === "debited" ? parseFloat(item.amount) : 0,
          payment_names: [item.payment_name],
        });
      }
    });

    // Sort the result by due_date
    return result.sort(
      (a: any, b: any) =>
        new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
    );
  }, [filteredData]);

  if (isLoading || isFetching) {
    return (
      <div className="flex justify-evenly items-center h-full">
        <Loader size="medium" withWhiteBg={true} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const handleYearChange = (e: any) => {
    setSelectedYear(e.target.value);
  };

  return (
    <div>
      <div className="flex justify-between px-4 items-baseline">
        <h2 className="font-semibold text-base mb-4">
          Yearly Payment Overview
        </h2>
        <div className="flex space-x-4">
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={selectedYear}
            onChange={handleYearChange}
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        {/* <BarChart data={processedData}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="due_date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="credited" fill="#82ca9d" />
          <Bar dataKey="debited" fill="#8884d8" />
        </BarChart> */}
        <AreaChart
          width={500}
          height={400}
          data={processedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="due_date" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="credited"
            stackId="1"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="debited"
            stackId="1"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export { BarChartByYear };
